<template>
  <div class="outer">
    <div class="middle">
      <div class="row">
        <div class="col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
          <img :src="tagline" alt="Logo" class="tagline-image">
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="inner col-sm-12 col-md-8">
            <div class="card-body">
              <h1 class="text-white text-bold mb-3">Login</h1>
              <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
              <form @submit.prevent="handleSubmit">
                <div class="form-group mb-3">
                  <label for="email" class="text-white">Email</label>
                  <input type="email" class="form-control" placeholder="Email" v-model="email" required autocomplete="on">
                </div>
                <div class="form-group mb-3">
                  <label for="password" class="text-white">Password</label>
                  <input type="password" class="form-control" placeholder="Password" v-model="password" required autocomplete="on">
                </div>
                <div class="row">
                  <div class="col-12">
                    <small style="color:white;">*Don't forget to change your password regularly to keep your account secure</small>
                    <button type="submit" class="btn btn-primary btn-block">Login</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    data(){
      return {
        email: '',
        password: '',
        error_message: '',
        tagline: require('../assets/tagline.png')
      }
    },
    computed: {
        ...mapState('users', ['status', 'onRequest']),
        ...mapState({
            alert: state => state.alert
        }),
        title(){
          return process.env.VUE_APP_NAME
        }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clearAlert();
        let email = this.email
        let password = this.password
        if (email && password) {
            this.login({ email, password })
        }
      },
      ...mapActions('users', ['login']),
      ...mapActions({
          clearAlert: 'alert/clear'
      })
    },
  }
</script>
<style>
.outer {
  background-image: url("/bg.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 98vh;
}

.middle {
  padding-top:20vh;
}

.tagline-image {
  max-width:50vw;
}
body {
   background: -webkit-linear-gradient(bottom, #0366fc, #03e8fc);
   background-repeat: no-repeat;
}
</style>
